import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const acceptOrder = async (orderId, refreshData, time) => {
    setLoading(true);
    try {
      await axios.post(
        `https://api.oldsicily.com/api/order/accept/${orderId}`,
        {
          time,
        },
      );
      refreshData();
    } catch (error) {
      console.error('Accept Order Error >>>', error);
    } finally {
      setLoading(false);
    }
  };

  const declineOrder = async (orderId, refreshData) => {
    setLoading(true);
    try {
      await axios.post(
        `https://api.oldsicily.com/api/order/decline/${orderId}`,
      );
      refreshData();
    } catch (error) {
      console.error('Decline Order Error >>>', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OrderContext.Provider value={{ acceptOrder, declineOrder, loading }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);

import React, { useState } from 'react';
import s from './OrderItem.module.scss';
import CloseIcon from '../../../../icons/Close';

const OrderItem = ({ product }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  function openPopup() {
    document.body.style.overflow = 'hidden';
    setIsPopupOpen(true);
  }

  function closePopup() {
    document.body.style.overflow = 'auto';
    setIsPopupOpen(false);
  }

  return (
    <>
      <div className={s.wrapper} onClick={openPopup}>
        <div className={s.image}>
          <div className={s.img__container}>
            <img src={product.imageUrl} alt="Product" className={s.img} />
          </div>
        </div>
        <div className={s.details}>
          <div className={s.name}>{product.englishName}</div>
          <div className={s.price}>
            <span>$</span>
            {product.price}
          </div>
          <div className={s.quantity}>
            Quantity: <span>{product.quantity}</span>
          </div>
        </div>
      </div>
      {isPopupOpen ? (
        <>
          <div className={s.modal}>
            <div className={s.popup__name}>{product.englishName}</div>
            <div className={s.popup__image}>
              <div className={s.img__container}>
                <img src={product.imageUrl} alt="Product" className={s.img} />
              </div>
            </div>
            <div className={s.description}>{product.orderDescription}</div>
            {product.comment ? (
              <div className={s.comment}>
                Comment: <p>{product.comment}</p>
              </div>
            ) : null}
            {product.bonus ? (
              <div className={s.comment}>
                Bonus: <p>{product.bonus}</p>
              </div>
            ) : null}
            <div className={s.popup__size}>Size: {product.size}</div>
            <button
              type={'button'}
              onClick={closePopup}
              className={s.popup__closeButton}>
              <CloseIcon />
            </button>
          </div>
          <div className={s.popup__close} onClick={closePopup} />
        </>
      ) : null}
    </>
  );
};

export default OrderItem;

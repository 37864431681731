import React, { useEffect, useState } from 'react';
import s from './Orders.module.scss';
import Order from '../../components/Order/Order';
import axios from 'axios';
import { useOrder } from '../../Contaxt/OrderContext/OrderContext';
import CloseIcon from '../../icons/Close';

const Orders = () => {
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  const { loading } = useOrder();

  const getData = async () => {
    try {
      const response = await axios.get(
        'https://api.oldsicily.com/api/order?status=created',
      );
      const newData = response.data.data;

      const previousOrderCount = localStorage.getItem('orderCount')
        ? parseInt(localStorage.getItem('orderCount'), 10)
        : 0;

      if (newData.length > previousOrderCount) {
        const difference = newData.length - previousOrderCount;
        setNewOrdersCount(difference);
        setShowPopup(true);
      }

      setData(newData.reverse());

      localStorage.setItem('orderCount', newData.length);
    } catch (error) {
      console.error('Ошибка при получении заказов >>>', error);
    }
  };

  useEffect(() => {
    getData();

    const intervalId = setInterval(getData, 7000);

    return () => clearInterval(intervalId);
  }, []);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  console.log('orders ===>', data);
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={s.head}>
          <div className={s.titles}>
            <div className={s.title}>ID</div>
            <div className={s.title}>Name</div>
            <div className={s.title}>Phone</div>
            <div className={s.title}>Price</div>
            <div className={s.title}>Status</div>
          </div>
          <div className={s.actions}>Action</div>
        </div>
        {loading ? (
          <div className={s.loading}>Loading..</div>
        ) : (
          <ul className={s.list}>
            {data.map(order => (
              <Order key={order.id} data={order} refreshData={getData} />
            ))}
          </ul>
        )}
      </div>

      {showPopup && (
        <>
          <div className={s.popup}>
            <div className={s.popup__content}>
              <p>You have {newOrdersCount} new orders!</p>
              <button className={s.popup__button} onClick={handlePopupClose}>
                OK
              </button>
            </div>
            <button
              type={'button'}
              onClick={handlePopupClose}
              className={s.popup__closeButton}>
              <CloseIcon />
            </button>
          </div>
          <div className={s.popup__close} onClick={handlePopupClose} />
        </>
      )}
    </div>
  );
};

export default Orders;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-subtitle: rgb(249, 235, 207);
  --color-subtitle-62: rgba(249, 235, 207, 0.62);
  --color-slider-title: rgb(255, 255, 255);
  --color-nav: rgb(226, 226, 226);
  --color-option-bg: rgb(57, 56, 56);
  --color-black: rgb(0, 0, 0);
  --color-black-62: rgba(0, 0, 0, 0.62);
  --color-white: rgb(255, 255, 255);
  --color-white-5: rgba(255, 255, 255, 0.5);
  --color-white-72: rgba(255, 255, 255, 0.718);
  --color-cart-count-bg: rgba(229, 0, 27, 0.77);
  --color-cart-main: rgb(42, 110, 79);
  --color-cart-main-8: rgba(42, 110, 79, 0.8);
  --color-cart-currency: rgb(248, 198, 35);
  --color-footer-bg: rgba(10, 10, 10, 0.9);
  --color-menu: rgb(251, 251, 251);
  --color-menu-6: rgba(251, 251, 251, 0.645);
  --color-modal-bg: rgb(18, 22, 24);
  --color-dot-bg: rgb(217, 217, 217);

}

:root {
  --tablet-xxl: 1025px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,8CAA8C;EAC9C,wCAAwC;EACxC,+BAA+B;EAC/B,kCAAkC;EAClC,2BAA2B;EAC3B,qCAAqC;EACrC,iCAAiC;EACjC,yCAAyC;EACzC,4CAA4C;EAC5C,6CAA6C;EAC7C,mCAAmC;EACnC,2CAA2C;EAC3C,wCAAwC;EACxC,wCAAwC;EACxC,gCAAgC;EAChC,0CAA0C;EAC1C,iCAAiC;EACjC,kCAAkC;;AAEpC;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[":root {\n  --color-subtitle: rgb(249, 235, 207);\n  --color-subtitle-62: rgba(249, 235, 207, 0.62);\n  --color-slider-title: rgb(255, 255, 255);\n  --color-nav: rgb(226, 226, 226);\n  --color-option-bg: rgb(57, 56, 56);\n  --color-black: rgb(0, 0, 0);\n  --color-black-62: rgba(0, 0, 0, 0.62);\n  --color-white: rgb(255, 255, 255);\n  --color-white-5: rgba(255, 255, 255, 0.5);\n  --color-white-72: rgba(255, 255, 255, 0.718);\n  --color-cart-count-bg: rgba(229, 0, 27, 0.77);\n  --color-cart-main: rgb(42, 110, 79);\n  --color-cart-main-8: rgba(42, 110, 79, 0.8);\n  --color-cart-currency: rgb(248, 198, 35);\n  --color-footer-bg: rgba(10, 10, 10, 0.9);\n  --color-menu: rgb(251, 251, 251);\n  --color-menu-6: rgba(251, 251, 251, 0.645);\n  --color-modal-bg: rgb(18, 22, 24);\n  --color-dot-bg: rgb(217, 217, 217);\n\n}\n\n:root {\n  --tablet-xxl: 1025px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import s from './Layout.module.scss';
import Header from './components/Header/Header';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className={s.wrapper}>
      <Header />
      <Outlet />
    </div>
  );
};

export default Layout;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `\$desktop-xxl: 1800px;
\$desktop-xl: 1700px;
\$desktop-l: 1600px;

\$tablet-xxl: 1366px;
\$tablet-xl: 900px;

\$mobile-xxl: 750px;
\$mobile-xl: 600px;

@mixin desktop-xxl {
  @media (max-width: \$desktop-xxl) {
    @content;
  }
}

@mixin desktop-xl {
  @media (max-width: \$desktop-xl) {
    @content;
  }
}

@mixin desktop-l {
  @media (max-width: \$desktop-l) {
    @content;
  }
}

@mixin tablet-xxl {
  @media (max-width: \$tablet-xxl) {
    @content;
  }
}

@mixin tablet-xl {
  @media (max-width: \$tablet-xl) {
    @content;
  }
}

@mixin mobile-xl {
  @media (max-width: \$mobile-xl) {
    @content;
  }
}

@mixin mobile-xxl {
  @media (max-height: \$mobile-xxl) {
    @content;
  }
}


`, "",{"version":3,"sources":["webpack://./src/styles/media.scss"],"names":[],"mappings":"AAAA,oBAAoB;AACpB,mBAAmB;AACnB,kBAAkB;;AAElB,mBAAmB;AACnB,iBAAiB;;AAEjB,kBAAkB;AAClB,iBAAiB;;AAEjB;EACE;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,QAAQ;EACV;AACF","sourcesContent":["$desktop-xxl: 1800px;\n$desktop-xl: 1700px;\n$desktop-l: 1600px;\n\n$tablet-xxl: 1366px;\n$tablet-xl: 900px;\n\n$mobile-xxl: 750px;\n$mobile-xl: 600px;\n\n@mixin desktop-xxl {\n  @media (max-width: $desktop-xxl) {\n    @content;\n  }\n}\n\n@mixin desktop-xl {\n  @media (max-width: $desktop-xl) {\n    @content;\n  }\n}\n\n@mixin desktop-l {\n  @media (max-width: $desktop-l) {\n    @content;\n  }\n}\n\n@mixin tablet-xxl {\n  @media (max-width: $tablet-xxl) {\n    @content;\n  }\n}\n\n@mixin tablet-xl {\n  @media (max-width: $tablet-xl) {\n    @content;\n  }\n}\n\n@mixin mobile-xl {\n  @media (max-width: $mobile-xl) {\n    @content;\n  }\n}\n\n@mixin mobile-xxl {\n  @media (max-height: $mobile-xxl) {\n    @content;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import s from './Picture.module.scss';
import ChangeIcon from '../../icons/Change';
import defaultImage from './../../images/picture/upload.svg';

const Picture = ({ initialImage, onImageChange, disabled }) => {
  const [previewImage, setPreviewImage] = useState(defaultImage);

  useEffect(() => {
    if (initialImage) {
      if (typeof initialImage === 'string') {
        setPreviewImage(initialImage);
      } else if (initialImage instanceof Blob) {
        setPreviewImage(URL.createObjectURL(initialImage));
      }
    } else {
      setPreviewImage(defaultImage);
    }
  }, [initialImage]);

  const handleImageChange = event => {
    const file = event.target.files[0];

    if (file) {
      onImageChange(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uniqueKey = Math.random().toString(36).substring(2, 15);

  return (
    <div className={s.wrapper}>
      <div className={s.image}>
        <div className={s.img__container}>
          <img
            src={previewImage}
            alt="Product"
            className={s.img}
            key={uniqueKey}
          />
        </div>
      </div>
      {!disabled ? (
        <div className={s.change}>
          <label
            htmlFor={`image-input-${uniqueKey}`}
            className={s.change__label}>
            <ChangeIcon />
          </label>
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/HEIC"
            id={`image-input-${uniqueKey}`}
            className={s.change__input}
            onChange={handleImageChange}
            disabled={disabled}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Picture;

import React, { useState } from 'react';
import s from './Order.module.scss';
import classNames from 'classnames';
import ArrowIcon from '../../icons/Arrow';
import OrderItem from './components/OrderItem/OrderItem';
import { useOrder } from '../../Contaxt/OrderContext/OrderContext';

const Order = ({ bequeathed, data = {}, refreshData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { acceptOrder, declineOrder, loading } = useOrder();

  const [time, setTime] = useState('');

  const handleAccept = async () => {
    if (!time) {
      alert('Please select a time or enter a custom time!');
      return;
    }
    await acceptOrder(data.id, refreshData, time);
  };

  const handleDecline = async () => {
    await declineOrder(data.id, refreshData);
  };

  return (
    <li className={s.wrapper}>
      <div
        className={s.head}
        onClick={() => setIsOpen(prevState => !prevState)}>
        <div className={s.info}>
          <div className={s.info__item}>{data.id}</div>
          <div className={s.info__item}>{data.name}</div>
          <div className={s.info__item}>{data.phone}</div>
          <div className={s.info__item}>{`$${data.totalPrice}`}</div>
          <div className={s.info__item}>{data.status}</div>
        </div>
        {!bequeathed && !loading ? (
          <div className={s.actions} onClick={e => e.stopPropagation()}>
            <div className={s.action}>
              <button
                type="button"
                className={s.action__button}
                onClick={handleAccept}>
                Accept
              </button>
            </div>
            <div className={s.action}>
              <button
                type="button"
                className={s.action__button}
                onClick={handleDecline}>
                {' '}
                Decline
              </button>
            </div>
          </div>
        ) : null}
        <div className={classNames(s.open, isOpen && s.opened)}>
          <button className={s.open__button}>
            <ArrowIcon />
          </button>
        </div>
      </div>
      {isOpen && (
        <>
          <div className={s.body}>
            <ul className={s.list}>
              {data?.orders?.map(product => (
                <li key={product.id} className={s.item}>
                  <OrderItem product={product} />
                </li>
              ))}
            </ul>
          </div>
          <div className={s.footer}>
            <div className={s.address}>{data.address}</div>
            <div className={s.details}>
              {!bequeathed ? (
                <div className={s.time}>
                  <input
                    placeholder="Min"
                    className={s.time__input}
                    value={isNaN(time) ? '' : time}
                    onChange={e => {
                      setTime(e.target.value);
                    }}
                  />
                  <button
                    type="button"
                    className={classNames(
                      s.time__button,
                      time === '30' && s.selected,
                    )}
                    onClick={() => {
                      setTime('30');
                    }}>
                    30 min
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      s.time__button,
                      time === '45' && s.selected,
                    )}
                    onClick={() => {
                      setTime('45');
                    }}>
                    45 min
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      s.time__button,
                      time === '60' && s.selected,
                    )}
                    onClick={() => {
                      setTime('60');
                    }}>
                    60 min
                  </button>
                </div>
              ) : null}
              <div className={s.price}>
                <span>$</span>
                <div>{data.totalPrice}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </li>
  );
};

export default Order;

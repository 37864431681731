import { useRoutes } from 'react-router-dom';
import Products from '../pages/Products/Products';
import Orders from '../pages/Orders/Orders';
import History from '../pages/History/History';
import Layout from '../components/Layout/Layout';

export default function Router() {
  let element = useRoutes([
    {
      element: <Layout />,
      children: [
        { path: '/', element: <Products /> },
        { path: '/orders', element: <Orders /> },
        { path: '/history', element: <History /> },
      ],
    },
  ]);
  return element;
}

import React, { useState } from 'react';
import { useAuth } from '../../Contaxt/AuthContext/AuthContext';
import s from './Login.module.scss';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://api.oldsicily.com/api/auth/admin/sign-in',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
        },
      );

      const data = await response.json();

      if (data.data.token) {
        login(data.data.token);
      } else {
        alert('Authentication failed');
      }
    } catch (error) {
      alert('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={s.wrapper}>
      <form
        className={s.login}
        onSubmit={e => {
          e.preventDefault();
          handleLogin();
        }}>
        <input
          className={s.input}
          type="text"
          placeholder="Username"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <input
          className={s.input}
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <button className={s.button} type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default Login;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Picture_image__Oj6ip {
  width: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.Picture_image__Oj6ip::after {
  content: "";
  display: block;
  padding-top: 100%;
}

.Picture_img__container__lcNzV {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Picture_img__QtKAO {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.Picture_change__3sR1K {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  opacity: 0;
}

.Picture_wrapper__DY8d0 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.Picture_wrapper__DY8d0:hover .Picture_change__3sR1K {
  opacity: 1;
}

.Picture_change__label__Y6Orj {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Picture_change__label__Y6Orj svg {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  object-position: center;
  fill: var(--color-white-72);
}

.Picture_change__input__4j77E {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Pictue/Picture.module.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AADF;AAGE;EACE,WAAA;EACA,cAAA;EACA,iBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,QAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,QAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,UAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAFF;AAIE;EACE,UAAA;AAFJ;;AAMA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAHF;AAME;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,2BAAA;AAJJ;;AAQA;EACE,aAAA;AALF","sourcesContent":["@import \"./../../styles/pxToRem\";\n\n.image {\n  width: rem(100px);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n\n  &::after {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n}\n\n.img__container {\n  position: absolute;\n  inset: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  object-position: center;\n}\n\n.change {\n  position: absolute;\n  inset: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  transition: 0.2s;\n  opacity: 0;\n}\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n\n  &:hover .change{\n    opacity: 1;\n  }\n}\n\n.change__label {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n\n\n  svg {\n    width: rem(32px);\n    height: rem(32px);\n    object-fit: cover;\n    object-position: center;\n    fill: var(--color-white-72);\n  }\n}\n\n.change__input {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `Picture_image__Oj6ip`,
	"img__container": `Picture_img__container__lcNzV`,
	"img": `Picture_img__QtKAO`,
	"change": `Picture_change__3sR1K`,
	"wrapper": `Picture_wrapper__DY8d0`,
	"change__label": `Picture_change__label__Y6Orj`,
	"change__input": `Picture_change__input__4j77E`
};
export default ___CSS_LOADER_EXPORT___;

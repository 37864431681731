// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,*:before,*:after {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.ellipsis {
  overflow: hidden;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

html {
  font-size: 16px;
  font-family: 'Josefin Sans', Roboto, sans-serif;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: #c0bfbf;
  }

  &::-webkit-scrollbar-thumb {
    background: #181717;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #3C414C;
  }
}










body {
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,SAAS;AACX;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,+CAA+C;EAC/C;IACE,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;AACF;;;;;;;;;;;AAWA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":["* {\n  padding: 0;\n  margin: 0;\n  border: 0;\n}\n\n*,*:before,*:after {\n  box-sizing: border-box;\n}\n\n.hidden {\n  display: none;\n}\n\n.ellipsis {\n  overflow: hidden;\n  flex-grow: 1;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\nhtml {\n  font-size: 16px;\n  font-family: 'Josefin Sans', Roboto, sans-serif;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: #c0bfbf;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: #181717;\n  }\n\n  &::-webkit-scrollbar-thumb:hover {\n    background: #3C414C;\n  }\n}\n\n\n\n\n\n\n\n\n\n\nbody {\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

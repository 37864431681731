// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.History_wrapper__iYxId {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.History_container__yLF83 {
  width: 80%;
}

.History_head__raBkO {
  margin-bottom: 1.25rem;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0.625rem 1.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--color-white);
  background-color: var(--color-footer-bg);
  border-radius: 0.25rem;
  text-align: center;
  text-transform: capitalize;
}

.History_titles__MY0AK {
  width: 77%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
}

.History_title__5H4vm {
  padding: 0 0.75rem;
  font-size: 1rem;
  width: 25%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.History_actions__a7Wih {
  width: 20%;
}

.History_list__WqAv2 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/History/History.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,sBAAA;EACA,gBAAA;EACA,MAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;EACA,wCAAA;EACA,sBAAA;EACA,kBAAA;EACA,0BAAA;AADF;;AAIA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,cAAA;AADF;;AAIA;EACE,kBAAA;EACA,eAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAKA;EACE,SAAA;EACA,UAAA;EACA,qBAAA;AAFF","sourcesContent":["@import \"./../../styles/pxToRem\";\n\n.wrapper {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.container {\n  width: 80%;\n}\n\n.head {\n  margin-bottom: rem(20px);\n  position: sticky;\n  top: 0;\n  z-index: 10;\n  padding: rem(10px) rem(20px);\n  width: 100%;\n  display: flex;\n  align-items: center;\n  font-size: rem(14px);\n  font-weight: 400;\n  color: var(--color-white);\n  background-color: var(--color-footer-bg);\n  border-radius: rem(4px);\n  text-align: center;\n  text-transform: capitalize;\n}\n\n.titles {\n  width: 77%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-shrink: 0;\n}\n\n.title {\n  padding: 0 rem(12px);\n  font-size: rem(16px);\n  width: 25%;\n  text-align: center;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.actions {\n  width: 20%;\n}\n\n\n.list {\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `History_wrapper__iYxId`,
	"container": `History_container__yLF83`,
	"head": `History_head__raBkO`,
	"titles": `History_titles__MY0AK`,
	"title": `History_title__5H4vm`,
	"actions": `History_actions__a7Wih`,
	"list": `History_list__WqAv2`
};
export default ___CSS_LOADER_EXPORT___;

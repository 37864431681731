import React, { useState } from 'react';
import s from './Select.module.scss';
import classNames from 'classnames';
import DownIcon from '../../../icons/Down';

const Select = ({ options, value, setValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={s.wrapper}>
      <div
        className={s.head}
        onClick={() => setIsOpen(prevState => !prevState)}>
        <div className={s.selectedValue}>{value}</div>
        <div className={classNames(s.arrow, isOpen && s.open)}>
          <DownIcon />
        </div>
      </div>
      {isOpen ? (
        <>
          <div className={s.body}>
            {options.map(option => (
              <div
                key={option}
                className={s.option}
                onClick={() => {
                  setValue(option);
                  setIsOpen(false);
                }}>
                {option}
              </div>
            ))}
          </div>
          <div className={s.close} onClick={() => setIsOpen(false)} />
        </>
      ) : null}
    </div>
  );
};

export default Select;

import React, { useState } from 'react';
import s from './Header.module.scss';
import LogoIcon from '../../../../icons/Logo';
import classNames from 'classnames';
import UserIcon from '../../../../icons/User';
import HomepageIcon from '../../../../icons/Homepage';
// import ChangePasswordIcon from '../../../../icons/ChangePassword';
import LogoutIcon from '../../../../icons/Logout';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../Contaxt/AuthContext/AuthContext';
const Header = () => {
  const [isUserPageOpen, setIsUserPageOpen] = useState(false);
  const { logout } = useAuth();
  const location = useLocation();

  return (
    <div className={s.wrapper}>
      <div className={s.head}>
        <div className={s.container}>
          <div className={s.logo}>
            <a
              href="https://oldsicily.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logo__link}>
              <LogoIcon />
            </a>
          </div>
          <nav className={s.nav}>
            <div
              className={classNames(s.nav__item, {
                [s.active]: location.pathname === '/',
              })}>
              <Link to={'/'} className={s.nav__link}>
                Products
              </Link>
            </div>
            <div
              className={classNames(s.nav__item, {
                [s.active]: location.pathname === '/orders',
              })}>
              <Link to={'/orders'} className={s.nav__link}>
                Orders
              </Link>
            </div>
            <div
              className={classNames(s.nav__item, {
                [s.active]: location.pathname === '/history',
              })}>
              <Link to={'/history'} className={s.nav__link}>
                History
              </Link>
            </div>
          </nav>
          <div className={s.user}>
            <div className={s.user__head}>
              <button
                className={s.user__button}
                onClick={() => setIsUserPageOpen(prevState => !prevState)}>
                <UserIcon />
              </button>
            </div>
            {isUserPageOpen ? (
              <>
                <div className={s.user__body}>
                  <div className={s.user__action}>
                    <a
                      href={'https://oldsicily.com/'}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      className={s.action__button}>
                      <div className={s.action__icon}>
                        <HomepageIcon />
                      </div>
                      <div className={s.action__text}>Main Page</div>
                    </a>
                  </div>
                  {/*<div className={s.user__action}>*/}
                  {/*  <button className={s.action__button}>*/}
                  {/*    <div className={s.action__icon}>*/}
                  {/*      <ChangePasswordIcon />*/}
                  {/*    </div>*/}
                  {/*    <div className={s.action__text}>Change Password</div>*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                  <div className={s.user__action}>
                    <button className={s.action__button} onClick={logout}>
                      <div className={s.action__icon}>
                        <LogoutIcon />
                      </div>
                      <div className={s.action__text}>Log out</div>
                    </button>
                  </div>
                </div>
                <div
                  className={s.user__close}
                  onClick={() => setIsUserPageOpen(false)}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

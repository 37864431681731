import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/routes';
import s from './App.module.scss';
import Login from './components/Login/Login';
import { AuthProvider, useAuth } from './Contaxt/AuthContext/AuthContext';
import { OrderProvider } from './Contaxt/OrderContext/OrderContext';

function AppContent() {
  const { token } = useAuth();
  return <div className={s.wrapper}>{token ? <Router /> : <Login />}</div>;
}

function App() {
  return (
    <AuthProvider>
      <OrderProvider>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </OrderProvider>
    </AuthProvider>
  );
}

export default App;

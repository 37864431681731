import React, { useEffect, useState } from 'react';
import s from './History.module.scss';
import Order from '../../components/Order/Order';
import axios from 'axios';
import { useOrder } from '../../Contaxt/OrderContext/OrderContext';

const History = () => {
  const [data, setData] = useState([]);
  const { loading } = useOrder();

  const getData = async () => {
    try {
      const response = await axios.get(
        'https://api.oldsicily.com/api/order?status=accepted&status=declined',
      );
      setData(response.data.data);
    } catch (error) {
      console.error('Get Accepted & Declined Orders Error >>>', error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={s.head}>
          <div className={s.titles}>
            <div className={s.title}>ID</div>
            <div className={s.title}>Full Name</div>
            <div className={s.title}>Date</div>
            <div className={s.title}>Price</div>
            <div className={s.title}>Status</div>
          </div>
        </div>
        {loading ? (
          <div className={s.loading}>Loading...</div>
        ) : (
          <ul className={s.list}>
            {data.map(order => (
              <Order
                bequeathed
                key={`${order.id}${order.orderDescription}${order.size}`}
                data={order}
                refreshData={getData}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default History;

const HomepageIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 441.556 441.555"
    {...props}>
    <path d="M405.584 262.377h-26.899v124.201c0 22-17.7 39.799-39.801 39.799h-236.3c-22 0-39.8-17.699-39.8-39.799V262.377h-26.9c-30 0-50.2-36.699-23.3-64.299l159.2-162.1c13.5-14.1 31.2-20.8 49-20.8s35.5 6.7 49.6 20.8l36.101 36.7v-32.4c0-14.1 11.6-25.1 25.7-25.1h21.399c13.5 0 25.101 11 25.101 25.1v106.5l50.199 51.4c26.801 27.5 7.301 64.199-23.3 64.199z" />
  </svg>
);
export default HomepageIcon;

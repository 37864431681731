import React, { useEffect, useState } from 'react';
import s from './Products.module.scss';
import AddIcon from '../../icons/Add';
import Product from './components/Product/Product';
import axios from 'axios';
import CloseIcon from '../../icons/Close';

const Products = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createProduct, setCreateProduct] = useState(false);
  const [message, setMessage] = useState('');

  const transformData = data => {
    return data.map(({ ProductImage, ...rest }) => ({
      ...rest,
      image: ProductImage?.imageUrl || '',
    }));
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.oldsicily.com/api/product');
      const transformedData = transformData(response.data.data);
      setData(transformedData);
      console.log('All Product Data >>>', response);
    } catch (error) {
      console.log('Update Product Error >>>', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePopupClose = () => {
    setMessage('');
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div className={s.loader}>
        <span className={s.loader__inner} />
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.outer}>
        <div className={s.head}>
          <div className={s.image}>image</div>
          <div className={s.name}>Product Name</div>
          <div className={s.typesAndPrices}>Types/Prices</div>
          <div className={s.description}>description</div>
          <div className={s.add}>
            <button
              type={'button'}
              className={s.add__button}
              onClick={() => setCreateProduct(prevState => !prevState)}>
              <AddIcon />
            </button>
          </div>
        </div>
        <div className={s.body}>
          {createProduct && (
            <Product
              setMessage={setMessage}
              getData={getData}
              setIsProductCreated={setCreateProduct}
              setLoading={setLoading}
            />
          )}
          {data
            ? data
                .slice()
                .reverse()
                .map(product => (
                  <Product
                    setMessage={setMessage}
                    key={product.id}
                    initialValues={product}
                    getData={getData}
                    setLoading={setLoading}
                  />
                ))
            : null}
        </div>
        {message && (
          <>
            <div className={s.popup}>
              <div className={s.popup__content}>
                <p>{message}</p>
                <button className={s.popup__button} onClick={handlePopupClose}>
                  OK
                </button>
              </div>
              <button
                type={'button'}
                onClick={handlePopupClose}
                className={s.popup__closeButton}>
                <CloseIcon />
              </button>
            </div>
            <div className={s.popup__close} onClick={handlePopupClose} />
          </>
        )}
      </div>
    </div>
  );
};

export default Products;
